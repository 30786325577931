import React, { Component } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Facebook, Twitter, Linkedin } from "react-feather"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Container from "../components/Container"
import ArticleCard from "../components/ArticleCard"

import "../styles/templates/article.scss"

class ArticlePage extends Component {
  render() {
    const { data, pageContext } = this.props
    const {
      site: { siteMetadata },
      markdownRemark: post,
      insightData,
    } = data

    const filteredData = insightData.edges.filter(item => {
      return item.node.frontmatter.title === post.frontmatter.title
    })

    return (
      <Layout>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          keywords={post.frontmatter.title.split(" ")}
          image={
            post.frontmatter.cover
              ? post.frontmatter.cover.childImageSharp.resize
              : null
          }
        />
        <div className="articlepage">
          <Container className="articlepage__title">
            <h1>{post.frontmatter.title}</h1>
            <span>{`${post.frontmatter.date} - ${post.timeToRead} min to read`}</span>
          </Container>
          <Img
            fluid={post.frontmatter.cover.childImageSharp.fluid}
            className="articlepage__cover"
          />
          <Container className="articlepage__container">
            <div
              className="articlepage__container-content"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
            <div className="articlepage__container-share">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${siteMetadata.siteUrl}${pageContext.slug}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook color="#1F3961" />
              </a>
              <a
                href={`https://twitter.com/share?url=${siteMetadata.siteUrl}${pageContext.slug}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter color="#1F3961" />
              </a>
              <a
                href={`https://www.linkedin.com/shareArticle?url=${siteMetadata.siteUrl}${pageContext.slug}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin color="#1F3961" />
              </a>
            </div>
          </Container>
          <Container className="articlepage__other">
            <h2>Insight Lainnya</h2>
            <div className="articlepage__other-content">
              {filteredData[0].next ? (
                <ArticleCard
                  link={filteredData[0].next.fields.slug}
                  date={filteredData[0].next.frontmatter.date}
                  title={filteredData[0].next.frontmatter.title}
                  description={filteredData[0].next.excerpt}
                  cover={
                    filteredData[0].next.frontmatter.cover.childImageSharp.fluid
                  }
                />
              ) : null}
              {filteredData[0].previous ? (
                <ArticleCard
                  link={filteredData[0].previous.fields.slug}
                  date={filteredData[0].previous.frontmatter.date}
                  title={filteredData[0].previous.frontmatter.title}
                  description={filteredData[0].previous.excerpt}
                  cover={
                    filteredData[0].previous.frontmatter.cover.childImageSharp
                      .fluid
                  }
                />
              ) : null}
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default ArticlePage

export const articlePageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        cover {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
    insightData: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "article-page" }
          isShow: { eq: true }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
          }
        }
        next {
          id
          frontmatter {
            title
            description
            date(formatString: "DD MMMM, YYYY")
            cover {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
        previous {
          id
          frontmatter {
            title
            description
            date(formatString: "DD MMMM, YYYY")
            cover {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
